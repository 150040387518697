import React, { createContext, Dispatch, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { AuthReducer, reducer } from './AuthReducer';
import { AUTHENTICATION_STATUS } from 'utils/constants';
import {
  AuthReducerAction,
  logoutAction,
  setAuthTokenAction,
  setStripeDetailsAction,
  setCheckedAction,
  setRefreshAuthPollAction,
} from './AuthActions';
import {
  AuthTokenPayload,
  clearAuthTokenFromStorage,
  getAuthTokenFromStorage,
  redirectToAuthUrl,
  setAuthTokenInStorage,
} from '../../utils/authUtils';

interface StripeDetails extends Document {
  lastFour: string;
}

export interface AuthState {
  stripeDetails: StripeDetails | {};
  authenticated: boolean | undefined;
  authToken?: string;
  tokenData?: AuthTokenPayload;
  tokenError?: string;
  refreshAuthTokenPoll?: boolean;
  checked: boolean;
}

export const initialState: AuthState = {
  authenticated: AUTHENTICATION_STATUS.NOT_LOADED,
  stripeDetails: {},
  checked: false,
};

export interface AuthContextState {
  state: AuthState;
  dispatch: Dispatch<AuthReducerAction>;
  setAuthToken: (authToken: string) => void;
  setStripeDetails: (lastFour: string) => void;
  setChecked: (checked: boolean) => void;
  loginRedirect: (returnTo?: string) => void;
  signupRedirect: (returnTo?: string) => void;
  logout: () => void;
}

const initialContextState: AuthContextState = {
  state: initialState,
  dispatch: () => undefined,
  setAuthToken: () => undefined,
  setStripeDetails: () => undefined,
  setChecked: () => undefined,
  loginRedirect: () => undefined,
  signupRedirect: () => undefined,
  logout: () => undefined,
};

export const AuthContext = createContext<AuthContextState>(initialContextState);

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer<AuthReducer>(reducer, initialState);

  const setAuthToken = useCallback((authToken: string) => {
    setAuthTokenInStorage(authToken);
    dispatch(setAuthTokenAction(authToken));
  }, []);

  const setStripeDetails = useCallback((lastFour: string) => {
    dispatch(setStripeDetailsAction(lastFour));
  }, []);

  const setChecked = useCallback((checked: boolean) => {
    dispatch(setCheckedAction(checked));
  }, []);

  const loginRedirect = useCallback((returnTo?: string) => {
    clearAuthTokenFromStorage();
    redirectToAuthUrl(false, returnTo);
  }, []);

  const signupRedirect = useCallback((returnTo?: string) => {
    clearAuthTokenFromStorage();
    redirectToAuthUrl(true, returnTo);
  }, []);

  const logout = useCallback(() => {
    clearAuthTokenFromStorage();
    dispatch(logoutAction());
  }, []);

  // On load, check if the token exists in localStorage and use that if so.
  useEffect(() => {
    const token = getAuthTokenFromStorage();

    if (token) {
      dispatch(setAuthTokenAction(token));
      dispatch(setRefreshAuthPollAction());
    } else {
      dispatch(logoutAction());
    }
  }, []);

  const token = getAuthTokenFromStorage();

  // Handle auth token changes.
  useEffect(() => {
    if (!token && state.authenticated) {
      dispatch(logoutAction());
      return;
    }

    if (token && token !== state.authToken) {
      dispatch(setAuthTokenAction(token));
    }
  }, [state.authToken, state.authenticated, token]);

  const contextValue = useMemo<AuthContextState>(
    () => ({
      state,
      dispatch,

      setAuthToken,
      setStripeDetails,
      setChecked,
      loginRedirect,
      signupRedirect,
      logout,
    }),
    [state, setAuthToken, setStripeDetails, loginRedirect, signupRedirect, logout, setChecked]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
}
