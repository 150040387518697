import { gql } from '@apollo/client';

export const stageFieldsFragment = gql`
  fragment StageFieldsFragment on Stage {
    id
    studio {
      id
      location {
        id
        address
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      directionsLink
    }
    name
    active
    zoneId
    details {
      fullName
      depositAmount
      stageThumbnail {
        url
        alt
      }
      carouselImages {
        url
        alt
      }
      aboutTheStage {
        header
        listItems
      }
    }
  }
`;
