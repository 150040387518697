import React, { useEffect } from 'react';

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { clearAuthTokenFromStorage, getAuthTokenFromStorage, setAuthTokenInStorage } from 'utils/authUtils';
import { HEADER_REFRESH_TOKEN } from '../../utils/constants';
import { onError } from '@apollo/client/link/error';
import { useAuthContext } from '../AuthContext/AuthContext';

interface AuthorizedApolloProviderProps {
  children: React.ReactNode;
}

const httpLink = createHttpLink({
  uri: process.env.GATSBY_GRAPHQL_API || 'http://localhost:8000/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthTokenFromStorage();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// After the backend responds, we take the refreshToken from headers if it exists, and save it in localStorage.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get(HEADER_REFRESH_TOKEN);
      if (refreshToken) {
        setAuthTokenInStorage(refreshToken);
      }
    }

    return response;
  });
});

const logoutLink = onError(({ response, graphQLErrors, networkError }) => {
  // @ts-ignore
  if (networkError && networkError?.statusCode === 401) {
    clearAuthTokenFromStorage();
    // Ignore the errors now to allow continuing.
    if (response) {
      // @ts-ignore
      response.errors = null;
    }

    // triggers logout in provider component
    client.clearStore();

    // clears store and triggers auth logout in AuthContext.tsx
    client.clearStore();
  } else if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === 'Unauthorized') {
        // every 401/unauthorized error will be caught here and remove the token from storage.
        clearAuthTokenFromStorage();
        // Ignore the errors now to allow continuing.
        if (response) {
          // @ts-ignore
          response.errors = null;
        }
      }
    });
  }
});

const client = new ApolloClient({
  link: authLink.concat(afterwareLink).concat(logoutLink).concat(httpLink),
  cache: new InMemoryCache(),
});

export function AuthorizedApolloProvider({ children }: AuthorizedApolloProviderProps) {
  const {
    state: { authenticated },
    logout,
  } = useAuthContext();

  useEffect(() => {
    // clear store and refetch queries after logout
    // @ts-ignore
    if (!authenticated && client?.clearStoreCallbacks?.length > 0) {
      client.resetStore();
    }

    // logout user onClearStore() triggered by 401
    // @ts-ignore
    if (authenticated && client?.clearStoreCallbacks?.length < 1) {
      client.onClearStore(async () => {
        if (authenticated && !getAuthTokenFromStorage()) {
          logout();
        }
      });
    }
  }, [authenticated, logout]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
