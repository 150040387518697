import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import { AUTH_CALLBACK_BASE_URL, AUTH_CALLBACK_REDIRECT_PATH, AUTH_STORAGE_KEY, MAIN_APP_AUTH_URL } from './constants';
import * as storage from './storage';
export interface AuthTokenPayload {
  iss: string;
  sub: string;
  exp: number;
  iat: number;
  aud?: string[] | string;
  nbf?: number;
  jti?: string;
}

export function decodeAuthToken(token: string): AuthTokenPayload | undefined {
  try {
    return jwtDecode<AuthTokenPayload>(token);
  } catch (err) {
    if (err instanceof InvalidTokenError) {
      return undefined;
    } else {
      throw err; // let other errors bubble up.
    }
  }
}

export function getAuthTokenFromStorage(): string | null {
  return storage.getItem(AUTH_STORAGE_KEY);
}

export function setAuthTokenInStorage(authToken: string): void {
  storage.setItem(AUTH_STORAGE_KEY, authToken);
}

export function clearAuthTokenFromStorage(): void {
  storage.removeItem(AUTH_STORAGE_KEY);
}

export function getAuthRedirectUrl(signUp = false, returnTo?: string): string {
  const redirectUrl = new URL(`${AUTH_CALLBACK_BASE_URL}${AUTH_CALLBACK_REDIRECT_PATH}`);

  // Add the current url add a query param to the redirectUrl so we know where to redirect
  // the user to after they login.
  const returnUrl = returnTo || window.location.pathname + window.location.search;
  redirectUrl.searchParams.set('returnTo', returnUrl);

  const params: Record<string, string> = {
    redirectUrl: redirectUrl.toString(),
    // Currently, the action won't be used on main app since it's the same process
    // if user is logging in vs. signing up, but building the distinction here
    // in case it's useful for metrics or different flow in the future.
    action: signUp ? 'signUp' : 'login',
  };

  const queryString = new URLSearchParams(params).toString();

  return `${MAIN_APP_AUTH_URL}?${queryString}`;
}

export function redirectToAuthUrl(signUp = false, returnTo?: string): void {
  if (typeof window !== 'undefined') {
    window.location.href = getAuthRedirectUrl(signUp, returnTo);
  }
}
