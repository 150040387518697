import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { UserProfile } from '@livebash/common-lib';
import useQueryUserProfile from 'hooks/useQueryUserProfile';
import useMutationRefreshAuthToken from 'hooks/useMutationRefreshToken';
import { mockUserProfile } from '../../data/mockUserProfile';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { AUTH_REFRESH_INTERVAL } from 'utils/constants';

interface UserContextState {
  userProfile?: UserProfile;
  userProfileLoading: boolean;
  userProfileError?: Error;
  refetchUserProfile: () => void;
  useMockData: boolean;
  setUseMockData: (enable: boolean) => void;
}

const initialState: UserContextState = {
  userProfile: undefined,
  userProfileLoading: false,
  userProfileError: undefined,
  useMockData: false,
  refetchUserProfile: () => undefined,
  setUseMockData: () => undefined,
};

export const UserContext = createContext<UserContextState>(initialState);

interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const {
    state: { refreshAuthTokenPoll },
  } = useAuthContext();
  const { refreshAuthToken } = useMutationRefreshAuthToken();
  const [useMockData, setUseMockData] = useState<boolean>(initialState.useMockData);
  const [refreshAuthPoll, setRefreshAuthPoll] = useState<NodeJS.Timeout | undefined>(undefined);
  const {
    data,
    loading: userProfileLoading,
    error: userProfileError,
    refetch: refetchUserProfile,
  } = useQueryUserProfile();

  // Clear/Start interval based on refresh auth token status
  if (refreshAuthTokenPoll && !refreshAuthPoll) {
    const interval = setInterval(async () => {
      await refreshAuthToken();
    }, AUTH_REFRESH_INTERVAL);
    setRefreshAuthPoll(interval);
  } else if (!refreshAuthTokenPoll && refreshAuthPoll) {
    clearInterval(refreshAuthPoll);
    setRefreshAuthPoll(undefined);
  }

  const contextValue = useMemo<UserContextState>(
    () => ({
      userProfile: useMockData ? mockUserProfile : data?.currentUser,
      userProfileLoading,
      userProfileError,
      refetchUserProfile,
      useMockData,
      setUseMockData,
    }),
    [data?.currentUser, refetchUserProfile, useMockData, userProfileError, userProfileLoading]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}
