export enum AuthAction {
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_STRIPE_DETAILS = 'SET_STRIPE_DETAILS',
  LOGOUT = 'LOGOUT',
  CHECKED = 'CHECKED',
  INITIATE_REFRESH_AUTH_POLL = 'INITIATE_REFRESH_AUTH_POLL',
}

export interface SetAuthTokenAction {
  type: AuthAction.SET_AUTH_TOKEN;
  payload: string;
}

export interface SetStripeDetailsAction {
  type: AuthAction.SET_STRIPE_DETAILS;
  payload: string;
}

export interface SetCheckedAction {
  type: AuthAction.CHECKED;
  payload: boolean;
}

export interface LogoutAction {
  type: AuthAction.LOGOUT;
}

export interface RefreshAuthPollAction {
  type: AuthAction.INITIATE_REFRESH_AUTH_POLL;
}

export type AuthReducerAction =
  | SetAuthTokenAction
  | LogoutAction
  | SetStripeDetailsAction
  | SetCheckedAction
  | RefreshAuthPollAction;

export const setAuthTokenAction = (authToken: string): SetAuthTokenAction => {
  return {
    type: AuthAction.SET_AUTH_TOKEN,
    payload: authToken,
  };
};

export const setStripeDetailsAction = (lastFour: string): SetStripeDetailsAction => {
  return {
    type: AuthAction.SET_STRIPE_DETAILS,
    payload: lastFour,
  };
};

export const setCheckedAction = (checked: boolean): SetCheckedAction => {
  return {
    type: AuthAction.CHECKED,
    payload: checked,
  };
};

export const logoutAction = (): LogoutAction => {
  return {
    type: AuthAction.LOGOUT,
  };
};

export const setRefreshAuthPollAction = (): RefreshAuthPollAction => {
  return {
    type: AuthAction.INITIATE_REFRESH_AUTH_POLL,
  };
};
