import { RadioFormProps } from './RadioButtonGroup';
import { ADDITIONAL_INFO_TOOLTIPS } from '../../../../utils/constants';

export const performanceTypeSectionData: RadioFormProps = {
  formData: {
    formLabel: 'Type of Performance:',
    formLabelId: 'performance-type-group-label',
    defaultValue: 'public',
    radioButtonData: [
      {
        radioLabel: 'Public',
        radioValue: 'public',
      },
      {
        radioLabel: 'Private',
        radioValue: 'private',
      },
    ],
  },
};

export const auctionNFTSectionData: RadioFormProps = {
  formData: {
    formLabel: 'Auction NFT of Your Performance:',
    formLabelId: 'auction-nft-group-label',
    defaultValue: 'yes',
    radioButtonData: [
      {
        radioLabel: 'Yes',
        radioValue: 'yes',
        sx: { marginRight: '2.5rem' },
      },
      {
        radioLabel: 'No',
        radioValue: 'no',
      },
    ],
  },
};

export const DCSSectionData: RadioFormProps = {
  formData: {
    formLabel: 'Digital Collectible Strategy:',
    formLabelId: 'dcs-group-label',
    defaultValue: 'exposure',
    radioButtonData: [
      {
        radioLabel: 'Exposure',
        radioValue: 'exposure',
        tooltipText: ADDITIONAL_INFO_TOOLTIPS.EXPOSURE_TOOLTIP_TEXT,
      },
      {
        radioLabel: 'Monetization',
        radioValue: 'monetization',
        tooltipText: ADDITIONAL_INFO_TOOLTIPS.MONETIZATION_TOOLTIP_TEXT,
      },
    ],
  },
};
