export const MAILCHIMP_URL =
  'https://livebash.us20.list-manage.com/subscribe/post?u=fef529a162d830c31ef8055ec&amp;id=6fe358c785';

export const SITE_URL = process.env.GATSBY_SITE_URL || 'https://livebash.com';
export const AUTH_CALLBACK_BASE_URL = process.env.GATSBY_AUTH_CALLBACK_BASE || SITE_URL;
export const MARKETPLACE_URL = process.env.GATSBY_MARKETPLACE_URL || 'https://app.livebash.com';
export const MAIN_APP_AUTH_URL = process.env.GATSBY_MAIN_APP_AUTH_URL || `${MARKETPLACE_URL}/authentication`;
export const AUTH_CALLBACK_REDIRECT_PATH = '/auth/callback';
export const EDIT_PROFILE_URL = 'https://qa.livebash.com/profile';
export const SUPPORT_URL = 'https://support.livebash.com';
export const FAQS_URL = 'https://support.livebash.com/hc/en-us/sections/7091347722907-FAQ';
export const FACEBOOK_SOCIAL_URL = 'http://www.facebook.com/livebashwastaken/';
export const INSTAGRAM_SOCIAL_URL = 'http://www.instagram.com/livebashwastaken/';
export const TIKTOK_SOCIAL_URL = 'http://www.tiktok.com/@livebashwastaken';
export const YOUTUBE_SOCIAL_URL = 'http://www.youtube.com/channel/UCPy_BPBPy0GU7rvRJ0HEmWA';
export const TWITTER_SOCIAL_URL = 'https://twitter.com/LiveBashIsTaken';
export const LINKEDIN_SOCIAL_URL = 'http://www.linkedin.com/company/live-bash/';

export const HEADER_REFRESH_TOKEN = 'X-LB-Refresh-Token';
export const AUTH_REFRESH_INTERVAL = process.env.GATSBY_AUTH_REFRESH_INTERVAL
  ? parseInt(process.env.GATSBY_AUTH_REFRESH_INTERVAL, 10)
  : 60000;

export const AUTH_STORAGE_KEY = 'livebash_creator_auth_token';
export const BOOKING_STORAGE_KEY = 'livebash_creator_booking_data';
export const ADDITIONAL_INFO_STORAGE_KEY = 'livebash_creator_additional_info';

export const BOOKING_PROGRESS_STATUS = {
  REVIEW_BOOKING: 'review_booking',
  ADD_PAYMENT: 'add_payment',
  REVIEW_BOOKING_PAYMENT: 'review_booking_payment',
  CONFIRM: 'confirm',
};

export const BOOKING_PROGRESS_BUTTON_TEXT = {
  REVIEW_BOOKING: 'Continue to Payment',
  ADD_PAYMENT: 'Continue to Booking',
  REVIEW_BOOKING_PAYMENT: 'Confirm Booking',
  CONFIRM: 'Done',
};

export const AUTHENTICATION_STATUS = {
  LOGGED_IN: true,
  NOT_LOGGED_IN: false,
  NOT_LOADED: undefined,
};

export const ADDITIONAL_INFO_TOOLTIPS = {
  EXPOSURE_TOOLTIP_TEXT: `
Exposure: “You sell a higher quantity of digital collectibles of your performance at a lower price point. We recommend this strategy if you’re trying to gain a new audience or want to make it more affordable for your fans to support your performance.”
`,
  MONETIZATION_TOOLTIP_TEXT: `
Monetization: “You sell a limited amount of digital collectibles of your performance at a higher price point. We recommend this strategy if you’re trying to maximize your ROI and help offset the cost of renting the stage.”
`,
};

export const PERFORMANCE_OTHER_NOTES_TITLES = {
  OTHER_MARKETING_NOTES_TEXT: `Please enter a short sentence describing other marketing notes for your performance, click save to save the list after you've edited it!`,
  OTHER_TECH_REQUESTS_TEXT: `Please enter a short sentence describing other tech requests you will need for your performance, click save to save the list after you've edited it!`,
};

export enum TECH_STACK_CATEGORIES {
  REQUESTED_EQUIPMENT = 'requestedEquipment',
  PERFORMER_EQUIPMENT = 'performerEquipment',
  OTHER_TECH_REQUESTS = 'otherTechRequests',
}

export enum MARKETING_MATERIALS_CATEGORIES {
  LOGO = 'logo',
  POSTERS_SOCIAL_MEDIA_ASSETS = 'postersSocialMediaAssets',
  WEBSITE = 'website',
  OTHER_MARKETING_NOTES = 'otherMarketingNotes',
  SOCIAL_MEDIA = 'socialMedia',
}

export enum PERFORMANCE_PLANNING_PROGRESS_TYPE {
  TECHSTACK_REQUEST = 'techstackRequest',
  MARKETING_MATERIAL = 'marketingMaterial',
}

export const PERFORMANCE_PLANNING_TOTAL_STEPS = 3;

export const LIVEBASH_TAGLINES = [
  `F*** IT, WE'LL DO IT LIVE...`,
  'PROVIDING MEMORIES WITH MUSIC,',
  `Let's book your next performance!`,
];

export const LIVEBASH_TAGLINES_ACCOUNT_PAGE = ['Placeholder'];

export const LIVEBASH_TAGLINES_ACCOUNT_PAGE_EXCLUDE = ['Placeholder'];

export enum LANDING_PAGE_FOCUS {
  Comedian = 'comedian',
  General = 'general',
  Musician = 'musician',
  Speaker = 'speaker',
}
