import { gql, QueryResult, useQuery } from '@apollo/client';
import { stageFieldsFragment } from 'gql/fragments/studios/stageFragments';
import { PerformanceLength, PerformanceType, Stage } from '@livebash/common-lib';

const GET_BOOKING_SEARCH_OPTIONS = gql`
  query GetBookingSearchOptions {
    performanceLengths {
      minutes
      label
    }
    performanceTypes {
      id
      name
      isDropdown
      subOptions {
        id
        name
      }
      category {
        id
        name
      }
    }
    stages {
      ...StageFieldsFragment
    }
  }

  ${stageFieldsFragment}
`;

export interface BookingSearchOptionsResult {
  performanceLengths: PerformanceLength[];
  performanceTypes: PerformanceType[];
  stages: Stage[];
}

export default function useQueryBookingSearchOptions(): QueryResult<BookingSearchOptionsResult> {
  return useQuery<BookingSearchOptionsResult>(GET_BOOKING_SEARCH_OPTIONS);
}
