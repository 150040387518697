import React from 'react';
import { createTheme, ThemeOptions } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';

export const HEADER_HEIGHT = 96;

interface ThemeColors {
  primary: string;
  cyan50: string;
  cyan100: string;
  cyan400: string;
  cyan500: string;
  cyan550: string;
  gray0_25: string;
  gray0_5: string;
  gray1: string;
  gray1_5: string;
  gray2: string;
  gray200: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray5_5: string;
  gray6: string;
  grayscale300: string;
  grayscale600: string;
  grayscale700: string;
  grayscale900: string;
  secondary: string;
  black: string;
  blue: string;
  blue100: string;
  blue300: string;
  blue700: string;
  blue750: string;
  blue800: string;
  blue900: string;
  pink100: string;
  pink500: string;
  softRed: string;
  brightRed: string;
  deepBlue: string;
  blueClickableLink: string;
  blueHighlight: string;
  blueAzure: string;
  green400: string;
  green500: string;
  green700: string;
  green800: string;
  green900: string;
  successGreen: string;
  endCall: string;
  limeHighlight: string;
  yellow200: string;
  yellow300: string;
  yellow400: string;
  yellow600: string;
  yellow900: string;
  orange: string;
  darkOrange: string;
  red100: string;
  red400: string;
  red500: string;
  red600: string;
  redShade: string;
  redError: string;
  purpleHighlight: string;
  lightBorder: string;
  lighterBorder: string;
  lightGrayButton: string;
  lightGrayDivider: string;
  liveBashGrey: string;
  grayDivider: string;
  lightGrayText: string;
  lightGrayTextAddress: string;
  lightGrayBackground: string;
  lightGrayMarketingText: string;
  mediumGrayBackground: string;
  offWhite: string;
  paypalYellow: string;
  navyGray: string;
}

//Allow custom breakpoints
interface BreakpointOverrides {
  xs: true;
  sm: true;
  md: true;
  lg: true;
  xl: true;
  ssf: true;
}

declare module '@mui/material/styles' {
  // Add custom theme variables.
  interface Theme {
    colors: ThemeColors;
    headerHeight: number;
    breakpoint: BreakpointOverrides;
  }

  // Allow configuration using `createMuiTheme`.
  interface ThemeOptions {
    colors: ThemeColors;
    headerHeight: number;
    breakpoint?: BreakpointOverrides;
  }

  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }

  // Add custom typography variants.
  // Docs: https://mui.com/customization/typography/#adding-amp-disabling-variants
  interface TypographyVariants {
    caption2: React.CSSProperties;
  }

  // Allow configuration using `createTheme`.
  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options.
// Docs: https://mui.com/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: true;
  }
}

// Update the Chip's color prop options.
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true;
  }
}

// Update the Chip's color prop options.
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const fontFamily = '"Sora", "Lato", "Helvetica", "Arial", sans-serif';

const colors: ThemeColors = {
  primary: '#121127',
  softRed: '#fca5a5',
  brightRed: '#ef4444',
  cyan50: '#e4fafc',
  cyan100: '#bbf3f7',
  cyan400: '#4edceb',
  cyan500: '#43d6e8',
  cyan550: '#3ec4d5',
  secondary: '#f25722',
  gray0_25: '#faf8f6',
  gray0_5: '#f2f1ef',
  gray1: '#e5e3df',
  gray1_5: '#ddd9d5',
  gray2: '#d5d0ca',
  gray200: '#e5e5eb',
  gray3: '#ada39d',
  gray4: '#6c6b80',
  gray5: '#615753',
  gray5_5: '#4a4644',
  gray6: '#c4c4c4',
  grayscale300: '#d1d1db',
  grayscale600: '#4c4b63',
  grayscale700: '#383751',
  grayscale900: '#7a7986',
  black: '#090915',
  blue: '#117da1',
  blue100: '#e7f2f6',
  blue300: '#a0cbd9',
  blue700: '#0e6481',
  blue750: '#0c5974',
  blue800: '#0a4b61',
  blue900: '#073240',
  pink100: '#fce7f3',
  pink500: '#ec4899',
  blueClickableLink: '#4285f4',
  deepBlue: '#121127',
  blueHighlight: '#d4e4f7',
  blueAzure: '#1294f2',
  green400: '#34d399',
  green500: '#10b981',
  green700: '#047857',
  green800: '#065f46',
  green900: '#064e3b',
  successGreen: '#059669',
  endCall: '#a3001d',
  limeHighlight: '#e7e9af',
  yellow200: '#fff4e6',
  yellow300: '#ffbc5d',
  yellow400: '#ffac3d',
  yellow600: '#ff9129',
  yellow900: '#ff9e2a',
  orange: '#ff461c',
  darkOrange: '#852f12',
  red100: '#fee2e2',
  red400: '#f87171',
  red500: '#ef4444',
  red600: '#dc2626',
  redShade: '#7c0016',
  redError: '#cc0000',
  purpleHighlight: '#cdc3cd',
  lightBorder: '#c4c4c4',
  lighterBorder: '#d9d9dc',
  lightGrayButton: 'rgba(18, 17, 39, 0.04)',
  lightGrayDivider: 'rgba(18, 17, 39, 0.12)',
  grayDivider: '#9d9caf',
  lightGrayText: '#a0a0a9',
  lightGrayTextAddress: '#84838e',
  lightGrayBackground: '#f0f0f0',
  lightGrayMarketingText: '#f9f9f9',
  liveBashGrey: '#e3e3e4',
  mediumGrayBackground: '#f5f5f5',
  offWhite: '#dadada',
  paypalYellow: '#ffc439',
  navyGray: '#383751',
};

const defaultTheme = createTheme();

// Override some of the default shadows.
// See https://mui.com/system/shadows/ or to see default values: https://mui.com/customization/default-theme/
const customShadows: Shadows = defaultTheme.shadows.slice() as Shadows;
customShadows[1] = '0 0.125rem 0.125rem rgba(0, 0, 0, 0.25)';

const customBreakpoints = {
  values: { ...defaultTheme.breakpoints.values, ssf: 384 },
};

export const themeOptions: ThemeOptions = {
  shape: {
    borderRadius: 8,
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    tertiary: {
      light: colors.yellow200,
      main: colors.yellow200,
      dark: colors.yellow900,
    },
    success: {
      main: colors.cyan100,
    },
    error: {
      main: colors.pink100,
    },
    text: {
      primary: colors.deepBlue,
    },
    tonalOffset: 0.3,
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          maxHeight: '31.25rem !important',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#ffffff',
          color: '#353435',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: `${HEADER_HEIGHT / 16}rem`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '0.375rem 1rem',
        },
        sizeSmall: {
          fontSize: '0.875rem',
          lineHeight: '1.5rem',
          padding: '0.375rem 1.25rem',
          borderRadius: '0.375rem',
          '& .MuiButton-endIcon.MuiButton-iconSizeSmall': {
            marginLeft: '0.125rem',
          },
        },
        sizeMedium: {
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.5rem',
          padding: '0.875rem 2rem',
          borderRadius: '0.5rem',
          '&.MuiButton-outlined': {
            padding: '0.625rem',
            borderWidth: '0.125rem',
          },
          '& .MuiButton-startIcon > *:nth-of-type(1)': {
            fontSize: '1.5rem',
          },
          '& .MuiButton-endIcon > *:nth-of-type(1)': {
            fontSize: '1.5rem',
          },
          '& .MuiButton-startIcon': {
            marginRight: '0.5rem',
            marginLeft: '-0.25rem',
          },
        },
        textPrimary: {
          ':hover': {
            backgroundColor: colors.blue100,
          },
        },
        outlinedSecondary: {
          borderColor: colors.secondary,
        },
        containedPrimary: {
          color: 'white',
          '&.Mui-disabled': {
            color: 'white',
            backgroundColor: colors.primary,
          },
        },
      },
      variants: [
        {
          props: { color: 'tertiary' },
          style: {
            backgroundColor: colors.yellow200,
            color: colors.yellow900,
            '&:hover': {
              color: 'white',
              backgroundColor: colors.yellow900,
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '2rem',
          '&.MuiChip-colorError': {
            '.MuiChip-label': {
              color: colors.pink500,
            },
          },
          '&.MuiChip-colorSuccess': {
            '.MuiChip-label': {
              color: colors.cyan500,
            },
          },
        },
        sizeSmall: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 600,
          letterSpacing: '0.015625rem',
          height: '1.5rem',
        },
        icon: {
          marginLeft: '0.3125rem',
          marginRight: '-0.375rem',
        },
        label: {
          padding: '0.0625rem 0.75rem',
          lineHeight: 1.8,
        },
        labelSmall: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },
      variants: [
        {
          props: { color: 'neutral' },
          style: {
            backgroundColor: colors.gray2,
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.gray3,
        },
        light: {
          borderColor: colors.lightGrayDivider,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          border: 'none',
          borderRadius: '0.5rem',
          '& .MuiFilledInput-root': {
            backgroundColor: 'unset',
          },
          '& .MuiFilledInput-root:before': {
            border: 'none',
          },
          '& .MuiFilledInput-root:after': {
            border: 'none',
          },
          '&:hover .MuiFilledInput-root:before': {
            border: 'none',
          },
          '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
            border: 'none',
          },
          '& .MuiFilledInput-input': {
            borderRadius: '0.5rem',
          },
          '& .MuiFilledInput-input:hover': {
            borderRadius: '0.5rem',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '0.5rem',
          borderRadius: '6.25rem',
          backgroundColor: colors.gray6,
        },
        bar: {
          backgroundColor: colors.yellow300,
          borderRight: '0.25rem solid #ffffff',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.yellow600,
          height: '0.25rem',
        },
        vertical: {
          '.MuiTabs-indicator': {
            height: '0.25rem !important',
            width: '100%',
            left: 0,
            // Using marginTop to be the height of the tab minus indicator height so that
            // the indicator is pushed down to the bottom instead of being forced on top by JS.
            marginTop: '2.75rem',
          },
          '.MuiTab-root': {
            textAlign: 'left',
            alignItems: 'flex-start',
            paddingLeft: 0,
            marginBottom: '1.875rem',
            '&:last-of-type': {
              marginBottom: 0,
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: colors.grayscale600,
          '&.Mui-selected': {
            color: colors.yellow600,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.yellow600,
          },
        },
      },
    },
  },
  typography: {
    fontFamily,
    fontSize: 16,
    h1: {
      fontSize: '3.5rem',
      fontWeight: 'bold',
      lineHeight: '120%',
      fontFamily: '"Sora", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontSize: '2.75rem',
      fontWeight: 'bold',
      lineHeight: 1.4,
      fontFamily: '"Sora", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: 1.1666666667,
      fontWeight: 'bold',
      fontFamily: '"Sora", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      letterSpacing: '0.009375rem',
      fontFamily: '"Sora", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      fontWeight: 'bold',
      letterSpacing: '0.009375rem',
      fontFamily: '"Sora", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      letterSpacing: '0em',
      fontFamily: '"Sora", "Helvetica", "Arial", sans-serif',
    },
    subtitle1: {
      fontSize: '1.25rem',
      letterSpacing: '0.0075em',
    },
    subtitle2: {
      fontSize: '1.125rem',
      lineHeight: 1.1111111111,
      fontWeight: 500,
      letterSpacing: '0.00625rem',
    },
    body1: {
      letterSpacing: '0em',
      fontSize: '1rem',
      fontFamily: 'Lato, "Lucida Grande", Tahoma, Sans-Serif',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      fontFamily: 'Lato, "Lucida Grande", Tahoma, Sans-Serif',
    },
    button: {
      fontSize: '1.125rem',
      fontWeight: '500',
      letterSpacing: '0.014em',
      textTransform: 'none',
    },
    caption: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 300,
      letterSpacing: '0',
    },
    overline: {
      fontSize: '0.875rem',
      fontWeight: 700,
      letterSpacing: '0.8px',
      lineHeight: 1.7,
    },
    // Custom typography variants
    caption2: {
      // Need to define fontFamily here unlike others because this is a custom
      // variant and therefore doesn't have default values in the theme.
      fontFamily,
      fontSize: '0.75rem',
      lineHeight: 1.3333333333,
      fontWeight: 400,
      letterSpacing: '0.015625rem',
    },
  },
  shadows: customShadows,
  breakpoints: customBreakpoints,
  spacing: (factor: number) => `${0.5 * factor}rem`,

  // Custom-added properties below.
  colors,
  headerHeight: HEADER_HEIGHT,
};

export default createTheme(themeOptions);
