import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { BookingPricingResponse, BookingPricingVariables } from '@livebash/common-lib';

const GET_BOOKING_PRICING_QUERY = gql`
  query GetBookingPricing($input: BookingPricingInput!) {
    bookingPricing(input: $input) {
      depositAmountInCents
      totalAmountInCents
      requireFullPaymentAtBooking
      timeslotPricing {
        startDateTime
        endDateTime
        hourlyRateInCents
        amountInCents
        durationMinutes
        isTimeslotRate
        timeslotDescription
      }
    }
  }
`;

export interface BookingPricingQueryResult {
  bookingPricing: BookingPricingResponse;
}

export default function useQueryBookingPricing(
  options?: QueryHookOptions<BookingPricingQueryResult, BookingPricingVariables>
): QueryResult<BookingPricingQueryResult, BookingPricingVariables> {
  return useQuery<BookingPricingQueryResult, BookingPricingVariables>(GET_BOOKING_PRICING_QUERY, options);
}
