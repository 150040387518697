import React, { createContext, useState } from 'react';

interface BuzzwordsState {
  buzzwordsOn: boolean;
  toggleBuzzwords: () => void
}

interface BuzzwordsProviderProps {
  children: React.ReactNode,
}

const initialState: BuzzwordsState = {
  buzzwordsOn: true,
  toggleBuzzwords: function() {}
};

export const BuzzwordsContext = createContext<BuzzwordsState>(initialState);
export function BuzzwordsProvider({ children }: BuzzwordsProviderProps) {
  const [buzzwordsOn, setBuzzwordsOn] = useState(true);
  const toggleBuzzwords = () => setBuzzwordsOn(!buzzwordsOn);

  return (
    <BuzzwordsContext.Provider value={{ buzzwordsOn, toggleBuzzwords }}>
      {children}
    </BuzzwordsContext.Provider>
  );
}

export const BuzzwordsConsumer = BuzzwordsContext.Consumer;