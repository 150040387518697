import { DateTime, DateTimeOptions } from 'luxon';

export type DateValue = DateTime | Date | string | number;

export function getDateTime(date?: DateValue, opts?: DateTimeOptions): DateTime {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date, opts);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date, opts);
  }

  if (date) {
    return DateTime.fromISO(date, opts);
  }

  return DateTime.now();
}

export type SimpleTimeOpts = {
  meridian?: boolean;
  offset?: boolean;
  meridianSpace?: boolean;
};

export function toSimpleTime(datetime: DateTime, opts: SimpleTimeOpts = {}) {
  const options: SimpleTimeOpts = {
    meridian: true,
    offset: false,
    meridianSpace: true,
    ...opts,
  };

  let format = 'h';

  if (datetime.minute > 0) {
    format += ':mm';
  }

  if (options.meridian) {
    if (options.meridianSpace) {
      format += ' ';
    }

    format += 'a';
  }

  if (options.offset) {
    format += ' ZZZZ';
  }

  return datetime.toFormat(format);
}
