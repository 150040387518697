import { gql, MutationFunction, MutationResult, OperationVariables, useMutation } from '@apollo/client';

const REFRESH_TOKEN = gql`
  mutation RefreshAuthToken {
    refreshAuthToken {
      success
    }
  }
`;

export interface RefreshAuthTokenResults {
  refreshAuthToken: {
    success: boolean;
  };
}

export interface RefreshAuthTokenMutationResults {
  mutationResults: MutationResult<RefreshAuthTokenResults>;
  refreshAuthToken: MutationFunction<RefreshAuthTokenResults, OperationVariables>;
}

export default function useMutationRefreshAuthToken(): RefreshAuthTokenMutationResults {
  const [refreshAuthToken, { ...result }] = useMutation(REFRESH_TOKEN);

  return {
    mutationResults: { ...result },
    refreshAuthToken,
  };
}
