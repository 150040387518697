exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-fan-tsx": () => import("./../../../src/pages/fan.tsx" /* webpackChunkName: "component---src-pages-fan-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-comedian-tsx": () => import("./../../../src/pages/landing-page/comedian.tsx" /* webpackChunkName: "component---src-pages-landing-page-comedian-tsx" */),
  "component---src-pages-landing-page-musician-tsx": () => import("./../../../src/pages/landing-page/musician.tsx" /* webpackChunkName: "component---src-pages-landing-page-musician-tsx" */),
  "component---src-pages-landing-page-speaker-tsx": () => import("./../../../src/pages/landing-page/speaker.tsx" /* webpackChunkName: "component---src-pages-landing-page-speaker-tsx" */),
  "component---src-pages-landing-page-success-tsx": () => import("./../../../src/pages/landing-page/success.tsx" /* webpackChunkName: "component---src-pages-landing-page-success-tsx" */),
  "component---src-pages-performer-chicago-tsx": () => import("./../../../src/pages/performer-chicago.tsx" /* webpackChunkName: "component---src-pages-performer-chicago-tsx" */),
  "component---src-pages-performer-tab-tsx": () => import("./../../../src/pages/performer/[...tab].tsx" /* webpackChunkName: "component---src-pages-performer-tab-tsx" */),
  "component---src-pages-press-release-tsx": () => import("./../../../src/pages/press-release.tsx" /* webpackChunkName: "component---src-pages-press-release-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

