import React from 'react';
import PropTypes from 'prop-types';
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { Helmet } from 'react-helmet';
import Link from '@mui/material/Link';
import { AuthorizedApolloProvider } from '../../context/AuthorizedApolloClientContext/AuthorizedApolloClientContext';
import { BuzzwordsProvider } from '../../context/BuzzwordsContext';
import { AuthProvider } from '../../context/AuthContext/AuthContext';
import { BookingProvider } from '../../context/BookingContext/BookingContext';
import { UserProvider } from '../../context/UserContext/UserContext';

const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
const LOAD_REVENUE_ROLL_SCRIPT = (process.env.GATSBY_LOAD_REVENUE_ROLL_SCRIPT || 'false').toLowerCase() === 'true';

TopLayout.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node,
};

export default function TopLayout({ children, theme }) {
  return (
    <>
      <CssBaseline />
      <Helmet titleTemplate='%s | Live Bash' defaultTitle='Live Bash'>
        {LOAD_REVENUE_ROLL_SCRIPT && (
          <script>{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://ss.livebash.com/ztvqtdto.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PR3BKGS');
          `}</script>
        )}
        {GOOGLE_OPTIMIZE_ID && <style>{`.async-hide {opacity: 0 !important}`}</style>}
        {GOOGLE_OPTIMIZE_ID && (
          <script>{`
            (function(a,s,y,n,c,h,i,d,e){s.className += ' ' + y;h.start=1*new Date;
            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
          })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'${GOOGLE_OPTIMIZE_ID}':true});window.gtag = window.gtag || function() { dataLayer.push(arguments) }
          `}</script>
        )}
        {GOOGLE_OPTIMIZE_ID && (
          <script src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_ID}`}></script>
        )}
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <meta
          name='description'
          content='Live Bash is a speculative entertainment platform for unique live content that provides performers, fans, and speculators with an all-in-one solution to create, purchase, and instantly monetize digital assets built on proprietary blockchain technology.'
        />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='' />
        <link href='https://fonts.googleapis.com/css2?family=Sora:wght@700&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Lato&family=Sora:wght@700&display=swap' rel='stylesheet' />
      </Helmet>
      <AuthProvider>
        <AuthorizedApolloProvider>
          <UserProvider>
            <ThemeTopLayout theme={theme}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <BookingProvider>
                  <BuzzwordsProvider>
                    <Link
                      id='scroll-to-top-anchor'
                      href='#top'
                      sx={{
                        visibility: 'hidden',
                        lineHeight: '0px',
                        width: '0px',
                        position: 'absolute',
                      }}
                    >
                      Top
                    </Link>
                    {children}
                  </BuzzwordsProvider>
                </BookingProvider>
              </LocalizationProvider>
            </ThemeTopLayout>
          </UserProvider>
        </AuthorizedApolloProvider>
      </AuthProvider>
    </>
  );
}
