import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  BookingPricingResponse,
  PerformanceLength,
  PerformanceType,
  Stage,
  StageDetails,
  TimekitProject,
} from '@livebash/common-lib';
import { DateTime } from 'luxon';
import useQueryBookingSearchOptions, {
  BookingSearchOptionsResult,
} from './useQueryBookingSearchOptions/useQueryBookingSearchOptions';
import * as storage from 'utils/storage';
import { ADDITIONAL_INFO_STORAGE_KEY, BOOKING_STORAGE_KEY } from 'utils/constants';
import { getDateTime } from 'utils/dateUtils';
import {
  auctionNFTSectionData,
  DCSSectionData,
  performanceTypeSectionData,
} from 'components/Booking/components/AdditionalInfo/AdditionalInfoSectionData';
import { RadioFormProps } from 'components/Booking/components/AdditionalInfo/RadioButtonGroup';
import useQueryBookingPricing from './useQueryBookingPricing/useQueryBookingPricing';

export interface BookingSearchValues {
  stage?: Stage;
  performanceType?: PerformanceType;
  date?: DateTime | null;
  performanceLength?: PerformanceLength;
}

export interface BookingOptions {
  stage: Stage;
  performanceType: PerformanceType;
  date: DateTime;
  performanceLength: PerformanceLength;
}

export interface BookingData {
  stage: Stage;
  performanceType: PerformanceType;
  performanceLength: PerformanceLength;
  performanceDateTime: DateTime;
  timekitProject?: TimekitProject;
  paymentMethodId?: string;
}

export interface AdditionalInfoData {
  performanceTypeSectionData: RadioFormProps;
  auctionNFTSectionData: RadioFormProps;
  DCSSectionData: RadioFormProps;
  performanceName?: string;
}

export const additionalInfoDataInitialState: AdditionalInfoData = {
  performanceTypeSectionData: {
    formData: performanceTypeSectionData.formData,
  },
  auctionNFTSectionData: {
    formData: auctionNFTSectionData.formData,
  },
  DCSSectionData: {
    formData: DCSSectionData.formData,
  },
  performanceName: undefined,
};

interface BookingDataFromStorage extends Omit<BookingData, 'performanceDateTime'> {
  performanceDateTime: string;
}

interface BookingState {
  searchValues: BookingSearchValues;
  bookingOptions?: BookingOptions;
  stageDetails?: StageDetails;
  bookingSearchOptions?: BookingSearchOptionsResult;
  booking?: BookingData;
  bookingPricing?: BookingPricingResponse;
  bookingPricingLoading: boolean;
  additionalInfo?: AdditionalInfoData;
  isBookingFromStorage: boolean;
  submitBookingOptions: (values: BookingOptions) => void;
  setBooking: (booking?: BookingData) => void;
  setAdditionalInfo: (additionalInfo?: AdditionalInfoData) => void;
  saveBookingInStorage: () => void;
  removeBookingFromStorage: () => void;
}

const initialStageId = 'chicago';

const initialState: BookingState = {
  additionalInfo: additionalInfoDataInitialState,
  searchValues: {},
  booking: undefined,
  bookingPricing: undefined,
  bookingPricingLoading: false,
  bookingOptions: undefined,
  isBookingFromStorage: false,
  stageDetails: undefined,
  submitBookingOptions: () => undefined,
  setBooking: () => undefined,
  setAdditionalInfo: () => undefined,
  saveBookingInStorage: () => undefined,
  removeBookingFromStorage: () => undefined,
};

export const BookingContext = createContext<BookingState>(initialState);

interface BookingProviderProps {
  children: React.ReactNode;
}

export function BookingProvider({ children }: BookingProviderProps) {
  const { data: bookingSearchOptions } = useQueryBookingSearchOptions();
  const [searchValues, setSearchValues] = useState<BookingSearchValues>(initialState.searchValues);
  const [bookingOptions, setBookingOptions] = useState<BookingOptions | undefined>(initialState.bookingOptions);
  const [stageDetails, setStageDetails] = useState<StageDetails | undefined>(initialState.stageDetails);
  const [booking, setBooking] = useState<BookingData | undefined>();
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfoData | undefined>(initialState.additionalInfo);
  const [isBookingFromStorage, setIsBookingFromStorage] = useState<boolean>(false);

  // Once the stages are loaded, set the initial stage.
  useEffect(() => {
    if (bookingSearchOptions && !searchValues.stage) {
      const stage = bookingSearchOptions.stages.find((x) => x.id === initialStageId);
      setSearchValues((prevState) => {
        return {
          ...prevState,
          stage,
        };
      });
      if (stage) {
        setStageDetails(stage.details);
      }
    }
  }, [bookingSearchOptions, searchValues.stage]);

  // Load booking pricing if we have a booking date/time selected.
  const { data: bookingPricingData, loading: bookingPricingLoading } = useQueryBookingPricing({
    skip: !booking || !booking.performanceDateTime,
    variables: booking
      ? {
          input: {
            stageId: booking.stage.id,
            performanceTypeId: booking.performanceType.id,
            performanceLengthMinutes: booking.performanceLength.minutes,
            performanceDateTime: booking.performanceDateTime.toISO(),
          },
        }
      : undefined,
  });

  const submitBookingOptions = useCallback((values: BookingOptions) => {
    setBookingOptions(values);
    setSearchValues(values);
    setStageDetails(values.stage.details);
  }, []);

  const saveBookingInStorage = useCallback(() => {
    storage.setItem(BOOKING_STORAGE_KEY, booking);
    storage.setItem(ADDITIONAL_INFO_STORAGE_KEY, additionalInfo);
  }, [booking, additionalInfo]);

  const removeBookingFromStorage = useCallback(() => {
    storage.removeItem(BOOKING_STORAGE_KEY);
    storage.removeItem(ADDITIONAL_INFO_STORAGE_KEY);
  }, []);

  // Load existing booking from storage if it's set.
  useEffect(() => {
    const bookingFromStorage = storage.getItem<BookingDataFromStorage>(BOOKING_STORAGE_KEY);
    const additionalInfoFromStorage = storage.getItem<AdditionalInfoData>(ADDITIONAL_INFO_STORAGE_KEY);

    if (bookingFromStorage) {
      const booking: BookingData = {
        ...bookingFromStorage,
        performanceDateTime: getDateTime(bookingFromStorage.performanceDateTime),
      };

      const bookingOptions: BookingOptions = {
        stage: booking.stage,
        performanceType: booking.performanceType,
        performanceLength: booking.performanceLength,
        date: booking.performanceDateTime,
      };

      setBooking(booking);
      setBookingOptions(bookingOptions);
      setSearchValues(bookingOptions);
      setStageDetails(bookingOptions.stage.details);
      setIsBookingFromStorage(true);
      storage.removeItem(BOOKING_STORAGE_KEY);
    }

    if (additionalInfoFromStorage) {
      const additionalInfo: AdditionalInfoData = {
        ...additionalInfoFromStorage,
      };

      setAdditionalInfo(additionalInfo);
      storage.removeItem(ADDITIONAL_INFO_STORAGE_KEY);
    }
  }, []);

  const state = useMemo<BookingState>(() => {
    return {
      searchValues,
      bookingOptions,
      stageDetails,
      bookingSearchOptions,
      submitBookingOptions,
      booking,
      bookingPricing: bookingPricingData?.bookingPricing,
      bookingPricingLoading,
      additionalInfo,
      isBookingFromStorage,
      setBooking,
      setAdditionalInfo,
      saveBookingInStorage,
      removeBookingFromStorage,
    };
  }, [
    searchValues,
    bookingOptions,
    stageDetails,
    bookingSearchOptions,
    submitBookingOptions,
    booking,
    bookingPricingData?.bookingPricing,
    bookingPricingLoading,
    additionalInfo,
    isBookingFromStorage,
    saveBookingInStorage,
    removeBookingFromStorage,
  ]);

  return <BookingContext.Provider value={state}>{children}</BookingContext.Provider>;
}

export function useBookingContext() {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBookingContext must be used within a BookingProvider');
  }
  return context;
}
