import { AuthState } from './AuthContext';
import { Reducer } from 'react';
import {
  AuthAction,
  AuthReducerAction,
  SetAuthTokenAction,
  SetCheckedAction,
  SetStripeDetailsAction,
} from './AuthActions';
import { AUTHENTICATION_STATUS } from '../../utils/constants';
import { decodeAuthToken } from '../../utils/authUtils';

export type AuthReducer = Reducer<AuthState, AuthReducerAction>;

function setAuthToken(state: AuthState, action: SetAuthTokenAction): AuthState {
  const tokenData = decodeAuthToken(action.payload);

  if (!tokenData) {
    return {
      ...state,
      authenticated: AUTHENTICATION_STATUS.NOT_LOGGED_IN,
      authToken: undefined,
      tokenData: undefined,
      tokenError: 'Failed to decode JWT token',
    };
  }

  return {
    ...state,
    authenticated: AUTHENTICATION_STATUS.LOGGED_IN,
    authToken: action.payload,
    tokenData,
    tokenError: undefined,
  };
}

function setStripeDetails(state: AuthState, action: SetStripeDetailsAction): AuthState {
  return {
    ...state,
    stripeDetails: {
      lastFour: action.payload,
    },
  };
}

function checkedTermsAndPolicy(state: AuthState, action: SetCheckedAction): AuthState {
  return {
    ...state,
    checked: action.payload,
  };
}

function logout(state: AuthState): AuthState {
  return {
    ...state,
    authenticated: AUTHENTICATION_STATUS.NOT_LOGGED_IN,
    authToken: undefined,
    tokenData: undefined,
    tokenError: undefined,
    refreshAuthTokenPoll: undefined,
  };
}

function initiateRefreshAuthPoll(state: AuthState): AuthState {
  return {
    ...state,
    refreshAuthTokenPoll: true,
  };
}

export const reducer: AuthReducer = (state: AuthState, action: AuthReducerAction) => {
  switch (action.type) {
    case AuthAction.SET_AUTH_TOKEN:
      return setAuthToken(state, action);

    case AuthAction.SET_STRIPE_DETAILS:
      return setStripeDetails(state, action);

    case AuthAction.CHECKED:
      return checkedTermsAndPolicy(state, action);

    case AuthAction.LOGOUT:
      return logout(state);

    case AuthAction.INITIATE_REFRESH_AUTH_POLL:
      return initiateRefreshAuthPoll(state);

    default:
      return state;
  }
};
