export function setItem<T = string>(key: string, value: T): void {
  if (isStorageAvailable()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function getItem<T = string>(key: string): T | null {
  if (isStorageAvailable()) {
    const value = localStorage.getItem(key);

    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to parse localStorage value for key ${key} as JSON`);
      }
    } else {
      return null;
    }
  }

  return null;
}

export function removeItem(key: string): void {
  if (isStorageAvailable()) {
    localStorage.removeItem(key);
  }
}

export function isStorageAvailable(): boolean {
  return typeof localStorage !== 'undefined';
}
