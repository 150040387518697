import { gql, QueryResult, useQuery } from '@apollo/client';
import { UserProfile } from '@livebash/common-lib';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useEffect } from 'react';

const GET_USER_PROFILE = gql`
  query GetUserProfile {
    currentUser {
      id
      username
      email
      avatar
      bio
      cover
      registerDate
    }
  }
`;

export interface UserProfileResult {
  currentUser: UserProfile;
}

export default function useQueryUserProfile(): QueryResult<UserProfileResult> {
  const {
    state: { authToken },
  } = useAuthContext();

  const { refetch, ...result } = useQuery<UserProfileResult>(GET_USER_PROFILE, {
    skip: !authToken,
  });

  // Reload the current user profile if the authToken changes.
  useEffect(() => {
    (async () => {
      if (authToken) {
        await refetch();
      }
    })();
  }, [authToken, refetch]);

  return {
    ...result,
    refetch,
  };
}
